/* ===============================
 *     01.  Toggle Menus         *
 *     02.  Clickable Menu       *
 =============================== */

//Menu
// Toggle menu
function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
    isOpen.style.display = "none";
  } else {
    isOpen.style.display = "block";
  }
};

// Clickable Menu
if(document.getElementById("navigation")){
  var elements = document.getElementById("navigation").getElementsByTagName("a");
  for(var i = 0, len = elements.length; i < len; i++) {
    elements[i].onclick = function (elem) {
      if(elem.target.getAttribute("href") === "javascript:void(0)") {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
      }
    }
  }
}
